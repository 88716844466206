import MuiGrid, { type Grid2Props } from "@mui/material/Unstable_Grid2"
import { type FC, memo } from "react"

export const Grid: FC<Grid2Props> = memo((props) => (
  <MuiGrid container spacing={props?.spacing ?? 2} {...props}>
    {props?.children}
  </MuiGrid>
))

Grid.displayName = "Grid"
