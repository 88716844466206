"use client"
import { track } from "@vercel/analytics"

import type { Locale } from "@/utils/translations/i18n-config"

/*************************************************
 * Tracking events (Google Tag Manager Trigger)
 *
 * Define here your tacking events, which will be added to Google Tag Manager
 */

export type CookieConsentPermission = "granted" | "denied"

export type CookieConsentPermissions = {
  ad_storage?: CookieConsentPermission
  ad_user_data?: CookieConsentPermission
  analytics_storage?: CookieConsentPermission
  ad_personalization?: CookieConsentPermission
}

export const formatTrackingName = (name?: string) => {
  if (!name) return "not_defined"
  return name
    .toLowerCase()
    .replace(/[^A-Za-z_]/g, "")
    .replaceAll(" ", "_")
}

// dataLayer will be defined in layout
const dataLayer = (args: object) => {
  const datatLayer = window.dataLayer
  if (typeof datatLayer === "undefined") return
  datatLayer.push(args)
}

const gtagExists =
  typeof window !== "undefined" && typeof window.gtag === "function"
/* Update cookie consent mode - https://support.google.com/google-ads/answer/10000067?hl=en */
export const updateCookieConsentMode = (args: CookieConsentPermissions) =>
  gtagExists && window?.gtag("consent", "update", args)

/* Tracks general page visits */
export const trackPageVisit = (url: string, GTMId?: string) =>
  gtagExists &&
  window.gtag("config", GTMId, {
    page_path: url,
  })

/* Tracks language changes */
export const trackLanguageChange = (locale: Locale, newLocale: Locale) => {
  track("Language change", {
    old_language: locale,
    new_language: newLocale,
  })
  dataLayer({
    event: "language_change",
    old_language: locale,
    new_language: newLocale,
  })
}

/* Mainnavigation opened */
export const trackMainNavigationState = (
  state: boolean,
  current_page: string,
) => {
  track("Mainnavigation state", {
    open: state,
    current_page,
  })
  dataLayer({
    event: "mainnavigation",
    state,
    current_page,
  })
}

/* Trigger for Dark/Light mode toogle */
export const trackDarkModeChange = (
  old_mode: "light" | "dark",
  new_mode: "light" | "dark",
  current_page: string,
) => {
  track("Theme Mode Change", {
    old_mode,
    new_mode,
    current_page,
  })
  dataLayer({
    event: "darkmode_change",
    old_mode,
    new_mode,
    current_page,
  })
}

/* Jump button has been triggered (Normally used if user does an interaction to jump to download area) */
export const trackCTAAppDownloadRedirect = (
  dom_parent: string,
  current_page: string,
) => {
  track("App Download (Redirect)", {
    location: dom_parent,
    current_page,
  })
  dataLayer({
    event: "download_app",
    dom_parent,
    current_page,
  })
}

/* Opened cookie consent */
export const trackCookieDialog = (dom_parent: string, state: boolean) => {
  track("Cookie consent changed", {
    open: state,
    location: dom_parent,
  })
  dataLayer({
    event: "cookie_dialog",
    dom_parent,
    state,
  })
}

/* Page redirect */
export const trackPageChange = (
  dom_parent: string,
  current_page: string,
  new_page: string,
  new_page_name?: string,
) => {
  track("Page changed", {
    new_page,
    new_page_name: formatTrackingName(new_page_name),
    dom_parent: formatTrackingName(dom_parent),
    location: dom_parent,
    current_page,
  })
  dataLayer({
    event: "page_change",
    dom_parent: formatTrackingName(dom_parent),
    current_page,
    new_page,
    new_page_name: formatTrackingName(new_page_name),
  })
}

/* User clicked an social button (for example in footer) */
export const trackSocialRedirect = (
  destination: string,
  current_page: string,
) => {
  track("Social Network Redirect", {
    destination,
    current_page,
  })
  dataLayer({
    event: "social_redirect",
    destination: formatTrackingName(destination),
    current_page,
  })
}

/********** KEY EVENTS (IMPORTANT! ONLY FOR CONVERSION ACTION USE) **********/

/* CTA - Newsletter subscriptions */
export const trackCTANewsletterSubscription = (
  dom_parent: string,
  current_page: string,
) => {
  track("Newsletter Subscription", {
    dom_parent,
    current_page,
  })
  dataLayer({
    event: "newsletter_subscription",
    dom_parent,
    current_page,
  })
}

/* CTA - App download */
export const trackCTAAppDownload = (
  dom_parent: string,
  current_page: string,
  store: "ios" | "android",
) => {
  // TODO: REMOVE THIS CONDITION IF ANDROID APP IS AVAILABLE
  if (store === "android") {
    trackCTAAppDownloadRedirect(dom_parent, current_page)
    return
  }
  track("App Download (Conversion)", {
    dom_parent,
    current_page,
  })
  dataLayer({
    event: "download_app_store",
    dom_parent,
    current_page,
    store,
  })
}
