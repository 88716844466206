import { type DocumentNode } from "graphql"
import useSWR from "swr"

import { graphqlFetcher } from "./fetcher"

// Generic SWR hook for GraphQL queries (CSR)
export const useGraphQL = <TData>(
  query: DocumentNode | string,
  variables: Record<string, any> = {},
  context: any = {},
) => {
  // Create a unique cache key for SWR based on query and variables
  const key = JSON.stringify({ query, variables })

  // Use SWR to fetch and cache the data, passing the context for revalidation
  const { data, error } = useSWR<TData>(
    key,
    () => graphqlFetcher<TData>(query, variables, context),
    {
      revalidateIfStale: context.revalidateIfStale || false,
      revalidateOnFocus: context.revalidateOnFocus || true, // Example of revalidation on focus
      revalidateOnReconnect: context.revalidateOnReconnect || true,
      refreshInterval: context.revalidateTime || 0, // Set SWR to revalidate every X milliseconds
    },
  )
  return {
    data,
    error,
    isLoading: !data && !error,
  }
}
