"use client"
import MatAccordion from "@mui/material/Accordion"
import AccordionActions from "@mui/material/AccordionActions"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"

import { useTheme } from "@mui/material/styles"

import { Animated } from "../../common/animated"
import { Button } from "../../common/button"
import { Headline } from "../../common/headline"
import { Icon } from "../../common/icon"

import type { AccordionProps } from "./Accordion.model"

import styles from "./Accordion.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Accordion")

export const Accordion = ({
  id,
  expanded,
  className,
  variant = "primary",
  titleOptions,
  iconProps,
  items,
  schema = {},
  ...props
}: AccordionProps) => {
  const theme = useTheme()
  return (
    <div className={bem(undefined, undefined, className)}>
      {items.map((item, a) => {
        const {
          className,
          title,
          renderHeader,
          renderContent,
          actions,
          ...rest
        } = item
        const accId = `${id}-${a}`
        const isExpanded = `${id}-${expanded}` === accId
        return (
          <MatAccordion
            key={accId}
            sx={{
              borderRadius: 0,
              overflow: "hidden",
              boxShadow: theme.shadows[1],
              "&:first-of-type": {
                borderTopLeftRadius: "0.75rem",
                borderTopRightRadius: "0.75rem",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
              "&:last-of-type": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: "0.75rem",
                borderBottomRightRadius: "0.75rem",
              },
              "& .MuiAccordion-region": {
                height: isExpanded ? "auto" : 0,
              },
              "& .MuiAccordionSummary-content": {
                margin: "7px 0",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "7px 0",
              },
              "& .MuiAccordionDetails-root": {
                opacity: isExpanded ? 1 : 0,
                height: isExpanded ? "auto" : 0,
                padding: "0px 32px 16px",
                color: theme.palette?.inherit.main,
                backgroundColor: theme.palette?.common?.white,
              },
              "& .MuiAccordionSummary-root": {
                backgroundColor: theme.palette?.common?.white,
              },
            }}
            {...rest}
            {...schema}
            className={bem(undefined, undefined, className)}
            content=""
            expanded={isExpanded}
            onChange={(e, open) => props.onChange(a, e, open)}
          >
            <AccordionSummary
              aria-controls={`${accId}-content`}
              id={`${accId}-header`}
              expandIcon={
                <Icon
                  name={isExpanded ? "MinusSignIcon" : "PlusSignIcon"}
                  size="sm"
                  color={
                    isExpanded
                      ? theme.palette?.inherit?.main
                      : theme.palette?.[variant]?.main
                  }
                  {...iconProps}
                />
              }
            >
              {renderHeader?.() ?? (
                <Headline
                  size="sm"
                  type="h3"
                  variant="inherit"
                  {...(item?.titleOptions ?? titleOptions)}
                >
                  {title}
                </Headline>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Animated className={bem("content")}>{renderContent()}</Animated>
            </AccordionDetails>
            {actions && actions?.length > 0 && (
              <AccordionActions>
                {actions.map((action, b) => (
                  <Button key={`${accId}-action-${b}`} {...action} />
                ))}
              </AccordionActions>
            )}
          </MatAccordion>
        )
      })}
    </div>
  )
}
