import type { IconName } from "@/components/common/icon"
import type { LottieAnimation } from "@/components/common/lottie"

export type LeadFormSolutionKey =
  | "ui-ux-design"
  | "software-development"
  | "app-development"
  | "project-management"
  | "cms"
  | "cloud-solutions"
  | "marketing"

export const LEAD_FORM_COMPANY_SIZES = [
  "startup",
  "scaling",
  "growing",
  "leader",
] as const

export const LEAD_FORM_AREAS = ["manage", "build", "scale"] as const

export const LEAD_FORM_STEPS = [
  "solution",
  "company",
  "details",
  "contact",
  "appointment",
] as const

export type LeadFormKey = (typeof LEAD_FORM_AREAS)[number]
export type LeadFormCompanySizeKey = (typeof LEAD_FORM_COMPANY_SIZES)[number]
export type LeadFormStepKey = (typeof LEAD_FORM_STEPS)[number]

export type LeadFormSolution = {
  key: LeadFormSolutionKey
  animation: LottieAnimation
}

export type LeadFormCompany = {
  key: LeadFormCompanySizeKey
  icon: IconName
}

export type LeadFormSolutions = {
  key: LeadFormKey
  icon: IconName
  items: LeadFormSolution[]
}

export const LEAD_FORM_COMPANY_SIZE: LeadFormCompany[] = [
  {
    key: "startup",
    icon: "StartUp02Icon",
  },
  {
    key: "growing",
    icon: "OfficeIcon",
  },
  {
    key: "scaling",
    icon: "City01Icon",
  },
  {
    key: "leader",
    icon: "City02Icon",
  },
]

export const LEAD_FORM_SOLUTIONS: LeadFormSolutions[] = [
  {
    key: "manage",
    icon: "CapRoundIcon",
    items: [
      {
        key: "project-management",
        animation: "SolutionProjectManagement",
      },
      {
        key: "cms",
        animation: "SolutionCMS",
      },
    ],
  },
  {
    key: "build",
    icon: "Wrench01Icon",
    items: [
      {
        key: "ui-ux-design",
        animation: "SolutionUIUXDesign",
      },
      {
        key: "software-development",
        animation: "SolutionSoftwareDevelopment",
      },
      {
        key: "app-development",
        animation: "SolutionAppDevelopment",
      },
    ],
  },
  {
    key: "scale",
    icon: "Rocket02Icon",
    items: [
      {
        key: "cloud-solutions",
        animation: "SolutionCloud",
      },
      {
        key: "marketing",
        animation: "SolutionMarketing",
      },
    ],
  },
]
