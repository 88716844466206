"use client"
import { GraphQLClient } from "graphql-request"

import { getStrapiURL } from "@/helpers/api-helpers"

const isServer = () => typeof window === "undefined"

// Function to create the GraphQL client
export const createGraphQLClient = () => {
  const adjustedUri =
    !isServer() && process.env.NODE_ENV === "development"
      ? "http://localhost:1337"
      : getStrapiURL()

  const token = process.env.NEXT_PUBLIC_STRAPI_API_TOKEN
  if (!token) throw new Error("The Strapi API Token is not set.")

  // Create the GraphQL client with authentication header
  return new GraphQLClient(`${adjustedUri}/graphql`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
}
