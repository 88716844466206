import { type DocumentNode } from "graphql"
import { GraphQLClient } from "graphql-request"

import { createGraphQLClient } from "@/config/graphql"

// Generic fetcher function for GraphQL queries
export const graphqlFetcher = async <TData>(
  query: DocumentNode | string,
  variables: Record<string, any> = {},
  context: { headers?: Record<string, string> } = {},
): Promise<TData> => {
  const graphQLClient: GraphQLClient = createGraphQLClient()
  if (context.headers) {
    graphQLClient.setHeaders(context.headers)
  }
  const response = await graphQLClient.request<TData>(query, variables)
  return response
}
