"use client"
import { create } from "zustand"
import { persist, devtools, subscribeWithSelector } from "zustand/middleware"

import { immerMiddleware } from "../immerMiddleware"

import type { LeadFormState } from "./LeadForm.model"

export const useLeadForm = create<LeadFormState>()(
  subscribeWithSelector(
    devtools(
      persist(
        immerMiddleware((set) => ({
          open: false,
          activeStep: "solution",
          updateLeadForm: (partialState: Partial<LeadFormState>) =>
            set((state) => Object.assign(state, partialState)),
        })),
        {
          name: "lead-form-storage", // Name of the item in storage
          // partialize: (state) => ({ count: state.count }), // Only persist the count state
          getStorage: () => localStorage, // Use localStorage as storage
        },
      ),
    ),
  ),
)
