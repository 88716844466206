"use client"
import { produce, type Draft } from "immer"

export const immerMiddleware =
  <T extends object>(
    config: (
      set: (fn: (draft: Draft<T>) => void) => void,
      get: () => T,
      api: any,
    ) => T,
  ): ((set: any, get: any, api: any) => T) =>
  (set, get, api) =>
    config((fn) => set(produce(fn)), get, api)
